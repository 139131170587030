// @refresh reload
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { Suspense } from "solid-js";
import { getRequestEvent, isServer } from "solid-js/web";
import "./app.css";
import RootContexts from "~/components/shared/RootContexts";
import { getMenuFooter, getMenuMain, getSettings } from "./lib/fetcher";
import { removeSearchParams } from "./utils/url";
import AltoStart from "./components/shared/AltoStart";

export default function App() {
  let rewriteMap: Record<string, string> = {};

  if (isServer) {
    const event = getRequestEvent();
    const pathname = removeSearchParams(event!.nativeEvent!.path);

    if (pathname) {
      rewriteMap[pathname] = event?.locals.startPath;
    }
  } else {
    // On the client, we're fucked with a dirty hack
    rewriteMap = (globalThis as unknown as Window).rewriteUrlsMapping;
  }

  const rewrite = (url: string) => {
    const u = new URL(url, "http://localhost/");
    return rewriteMap[u.pathname] ? rewriteMap[u.pathname] + u.search : url;
  };

  return (
    <Router
      explicitLinks={true}
      preload={false}
      transformUrl={rewrite}
      root={(props) => (
        <Suspense>
          <RootContexts>{props.children}</RootContexts>
        </Suspense>
      )}
      rootPreload={() => {
        getMenuMain();
        getMenuFooter();
        getSettings();
      }}
    >
      <FileRoutes />
      <AltoStart />
    </Router>
  );
}
