import { type ParentProps, Show } from "solid-js";

import { RealEstateContext } from "~/types/common";

import { StackingContextProvider } from "~/contexts/StackingContext";
import { RequestContextProvider } from "~/contexts/RequestContext";
import { ViewportSizeContextProvider } from "~/contexts/ViewportSizeContext";
import { DrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { FormContextProvider } from "~/contexts/FormContext";
import { MediaOverlayProvider } from "~/contexts/MediaOverlayContext";
import { EventsContextProvider } from "~/contexts/EventsContext";
import { DidomiProvider } from "~/contexts/DidomiContext";
import { MetaProvider } from "@solidjs/meta";
import { LotActiveProvider } from "~/contexts/LotActiveContext";
import { SearchProvider } from "~/contexts/SearchContext";
import { ModalFormProvider } from "~/contexts/ModalFormContext";
import { CapencyProvider } from "~/contexts/CapencyContext";

import { createAsync } from "@solidjs/router";
import { getMenuFooter, getMenuMain, getSettings } from "~/lib/fetcher";
import { Assets } from "solid-js/web";
import { Gtag } from "~/components/shared/Trackers/Gtag";
import { GtmScript } from "~/components/shared/Trackers/GtmScript";
import { GtmNoScript } from "~/components/shared/Trackers/GtmNoScript";

import UtmCollector from "~/components/UtmCollector";
import PageContent from "~/components/shared/PageContent";
import { FormStorageProvider } from "~/contexts/FormStorageProvider";
import { MapContextProvider } from "~/contexts/MapContext";
import { GtmVarsGlobal } from "./Trackers/GtmVars";

export default function RootContexts(props: ParentProps) {
  const menuMain = createAsync(() => getMenuMain(), {
    name: "menuMain",
    deferStream: true,
  });
  const menuFooter = createAsync(() => getMenuFooter(), {
    name: "menuFooter",
    deferStream: true,
  });
  const settings = createAsync(() => getSettings(), {
    name: "settings",
    deferStream: true,
  });

  const gtmEnabled = import.meta.env.VITE_COG_GTM_ENABLED === "1";
  const gaEnabled = import.meta.env.VITE_COG_GA_ENABLED === "1";

  return (
    <>
      <Show when={menuMain() && menuFooter() && settings()}>
        <MetaProvider>
          <DidomiProvider>
            <UtmCollector />
            <Show when={gaEnabled && settings()?.ga_tracking_id} keyed>
              <Assets>
                <Gtag ga_tracking_id={settings()!.ga_tracking_id} />
              </Assets>
            </Show>
            <Show when={gtmEnabled && settings()?.gtm_container_id} keyed>
              <GtmVarsGlobal cog_env={settings()!.cog_env} />
              <Assets>
                <GtmScript gtm_container_id={settings()!.gtm_container_id} />
              </Assets>
              <GtmNoScript gtm_container_id={settings()!.gtm_container_id} />
            </Show>

            <DrupalSettingsContext.Provider value={settings()}>
              <ViewportSizeContextProvider
                viewPortIsLessThan768={false}
                viewPortIsMoreThan768={false}
                viewPortIsLessThan1210={false}
                viewPortIsLessThan1024={false}
                viewPortIsLessThan1280={false}
              >
                <MediaOverlayProvider>
                  <StackingContextProvider
                    isTopBarHeaderStacked={false}
                    isProgramHeaderStacked={false}
                    isSearchResultsHeaderStacked={false}
                    hasPreventScrollEnabled={false}
                    preventScrollLayersCount={0}
                  >
                    <RequestContextProvider
                      isFrontPage={false}
                      realEstateContext={RealEstateContext.Unknown}
                    >
                      <EventsContextProvider
                        gtmEnabled={gtmEnabled}
                        gaEnabled={gaEnabled}
                        template="unknown"
                      >
                        <LotActiveProvider>
                          <CapencyProvider
                            enabled={settings()!.capency_enabled}
                          >
                            <FormStorageProvider>
                              <ModalFormProvider>
                                <FormContextProvider>
                                  <SearchProvider>
                                    <MapContextProvider>
                                      <PageContent
                                        menuMain={menuMain()!}
                                        menuFooter={menuFooter()!}
                                        settings={settings()!}
                                      >
                                        {props.children}
                                      </PageContent>
                                    </MapContextProvider>
                                  </SearchProvider>
                                </FormContextProvider>
                              </ModalFormProvider>
                            </FormStorageProvider>
                          </CapencyProvider>
                        </LotActiveProvider>
                      </EventsContextProvider>
                    </RequestContextProvider>
                  </StackingContextProvider>
                </MediaOverlayProvider>
              </ViewportSizeContextProvider>
            </DrupalSettingsContext.Provider>
          </DidomiProvider>
        </MetaProvider>
      </Show>
    </>
  );
}
