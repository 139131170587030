export function GtmScript(props: { gtm_container_id: string }) {
  const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${props.gtm_container_id}');`;

  return (
    <>
      {/* Google Tag Manager */}
      <script type="text/javascript" innerHTML={script} />
      {/* End Google Tag Manager */}
    </>
  );
}
